import React from 'react'

import { navigate } from '@reach/router'

import { AuthConsumer } from '../providers/AuthProvider'

import Layout from '../components/Layout'
import PartnerScreen from '../components/PartnerScreen'
import RoleScreen from '../components/RoleScreen'
import SEO from '../components/SEO'
import { Spin } from '../components/Spin'
import { Tabs, TabPane } from '../components/Tabs'

import { App } from 'redesign/components/App'

import { useFeatureFlags } from '../hooks/useFeatureFlags'

import { getActiveAnchor } from '../utils/helpers'

const RolesPageContent = ({ authData }) => {
  const onChangeHandler = tab => navigate(`#${tab}`)

  const { features } = useFeatureFlags()

  const isNewPartnerProfileEnabled = features?.RELEASE_PARTNER_PROFILE

  return isNewPartnerProfileEnabled ? (
    <RoleScreen authData={authData} features={features} />
  ) : (
    <Tabs
      className="content"
      type="card"
      defaultActiveKey={getActiveAnchor('roles')}
      onChange={onChangeHandler}
    >
      <TabPane tab={'Roles'} key="roles">
        <RoleScreen authData={authData} features={features} />
      </TabPane>
      <TabPane tab={'Partners'} key="partners">
        <PartnerScreen authData={authData} />
      </TabPane>
    </Tabs>
  )
}

const RolesPage = () => (
  <App>
    <Layout menuSelectedKeys={['roles']}>
      <SEO title="Roles" />

      <AuthConsumer>
        {({ isLoading, data }) => {
          if (isLoading) {
            return <Spin tip="Loading..." size="large" />
          }

          if (!data) {
            return <div>Not logged in</div>
          }

          return <RolesPageContent authData={data} />
        }}
      </AuthConsumer>
    </Layout>
  </App>
)

export default RolesPage
